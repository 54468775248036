<template>
    <a-drawer
        v-model:visible="visible"
        title="异常工时统计详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions :column="2">
            <a-descriptions-item label="报工流水号" :span="2">
                {{ detailInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="生产订单号">
                {{ detailInfo?.workOrderInfo?.orderCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ detailInfo?.workOrderInfo?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="项目编码">
                {{ detailInfo?.workOrderInfo?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="工单号">
                {{ detailInfo?.workOrderInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ detailInfo?.workOrderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码" :span="2">
                {{ detailInfo?.workOrderInfo?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ detailInfo?.workOrderInfo?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ detailInfo?.workOrderInfo?.produceTeamLeaderName }}
            </a-descriptions-item>
            <a-descriptions-item label="生产日期">
                {{ formateMoment(detailInfo?.productionStartTime, "YYYY-MM-DD") }}
            </a-descriptions-item>
            <a-descriptions-item label="生产时间">
                {{ formateMoment(detailInfo?.productionStartTime, "HH:mm") }} ~
                {{ formateMoment(detailInfo?.productionEndTime, "HH:mm") }}
            </a-descriptions-item>
            <a-descriptions-item label="生产时长">
                {{ detailInfo?.reportDurationHours }} 小时
            </a-descriptions-item>
            <a-descriptions-item label="生产总工时">
                {{ detailInfo?.reportDurationTotalHours }} 小时
            </a-descriptions-item>
            <a-descriptions-item label="提交人">
                {{ detailInfo?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
                {{ detailInfo?.createdTime }}
            </a-descriptions-item>
            <a-descriptions-item label="生产人员">
                <div class="members-box">
                    <div v-for="item in detailInfo?.workReportUsers" :key="item.id">
                        {{ item?.displayName }}&emsp;
                    </div>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="生产人数">
                {{ detailInfo?.workReportUsers?.length }}
            </a-descriptions-item>
            <a-descriptions-item label="小时工人数">
                {{ detailInfo?.hourlyWorkerCount || 0 }}
            </a-descriptions-item>
            <a-descriptions-item label="报工工序">
                {{ detailInfo?.workOrderStepName }}
            </a-descriptions-item>
            <a-descriptions-item label="报工进度">{{ detailInfo?.schedule }} %</a-descriptions-item>
            <a-descriptions-item label="现场图片" :span="2">
                <a-image-preview-group>
                    <a-image
                        v-for="item in detailInfo?.livePictureIds"
                        :key="item"
                        :src="`${baseURL}/web/mes-file-info/download/${item}`"
                        :width="100"
                        :height="100"
                    />
                </a-image-preview-group>
            </a-descriptions-item>
            <a-descriptions-item label="报工描述" :span="2">
                {{ detailInfo?.description }}
            </a-descriptions-item>
            <a-descriptions-item label="异常时长" v-if="detailInfo?.exception">
                {{ detailInfo?.exceptionWorkingHours }} 小时
            </a-descriptions-item>
            <a-descriptions-item label="异常总工时" v-if="detailInfo?.exception">
                {{ detailInfo?.exceptionTotalWorkingHours }} 小时
            </a-descriptions-item>
            <a-descriptions-item label="异常事项" v-if="detailInfo?.exception">
                {{ detailInfo?.exceptionMatter }}
            </a-descriptions-item>
            <a-descriptions-item label="异常类型" v-if="detailInfo?.exception">
                {{ detailInfo?.excReport?.excType }}
            </a-descriptions-item>
            <a-descriptions-item label="异常描述" v-if="detailInfo?.exception">
                {{ detailInfo?.exceptionDescription }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiJCWorkReportDetail } from "@/api";
import Select from "@/components/Select";
import { formateMoment } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import { useStore } from "vuex";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: ["detailId"],
    emits: ["eventUpdateOk", "update:detailId"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const { getters } = useStore();
        const state = reactive({
            visible: false,
            detailInfo: null,
            token: getters["user/token"],
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailId", null);
        };

        const getIndexData = async id => {
            let res = await apiJCWorkReportDetail(id);
            if (res.status === "SUCCESS") {
                state.detailInfo = res.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            formateMoment,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
